@font-face {
  font-family: SFUI;
  src: url(SFUIText-Semibold.5137a86a.eot);
  src: url(SFUIText-Semibold.5137a86a.eot#iefix) format("embedded-opentype"), url(SFUIText-Semibold.e6a4dac2.woff2) format("woff2"), url(SFUIText-Semibold.268dfe2f.woff) format("woff"), url(SFUIText-Semibold.3ff24852.ttf) format("truetype"), url(SFUIText-Semibold.872319fb.svg#SFUIText-Semibold) format("svg");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: SFUI;
  src: url(SFUIText-Medium.e649b9af.eot);
  src: url(SFUIText-Medium.e649b9af.eot#iefix) format("embedded-opentype"), url(SFUIText-Medium.3473cc66.woff2) format("woff2"), url(SFUIText-Medium.d9a8b748.woff) format("woff"), url(SFUIText-Medium.cb243394.ttf) format("truetype"), url(SFUIText-Medium.fa3a86b6.svg#SFUIText-Medium) format("svg");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: SFUI;
  src: url(SFUIText-Regular.2038e4a7.eot);
  src: url(SFUIText-Regular.2038e4a7.eot#iefix) format("embedded-opentype"), url(SFUIText-Regular.4d100c9f.woff2) format("woff2"), url(SFUIText-Regular.23eb2996.woff) format("woff"), url(SFUIText-Regular.a0083ab4.ttf) format("truetype"), url(SFUIText-Regular.e73fbd36.svg#SFUIText-Regular) format("svg");
  font-weight: 400;
  font-style: normal;
}
* {
  color: #324057;
  font-family: "SFUI", system-ui, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
}
html {
  background-color: #F9FAFC;
}
.MuiTypography-root {
  font-family: "SFUI", system-ui, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
  line-height: 1 !important;
}
.MuiButton-startIcon {
  display: block !important;
  position: absolute !important;
  margin: 0% !important;
}
.MuiAlert-root *, .MuiButton-contained * {
  color: #fff !important;
}
.count:before {
  content: " ";
  display: block;
  margin-bottom: 20px;
}
.CookieConsent div:not(.gdpr_content) {
  align-self: center;
}
.MuiBackdrop-root {
  background-color: transparent !important;
}
#mui-component-select-roadmap_id,
#mui-component-select-sorted_by {
  padding-bottom: 0px;
}
.MuiPaper-elevation1 {
  box-shadow: rgba(0, 0, 0, 0) 0px 2px 1px -1px, rgba(0, 0, 0, 0) 0px 1px 1px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px !important;
}
.MuiSelect-standard {
  font-size: 0.9rem !important;
  font-weight: bold !important;
  letter-spacing: 0px !important;
}
.MuiMenuItem-root {
  font-size: 14px !important;
}
.MuiButton-outlined {
  border: 1px solid rgba(215, 215, 215, 0.5) !important;
  border-radius: 12px !important;
}
.react-trello-lane {
  background-color: #fff !important;
  border-radius: 13px !important;
  padding: 20px !important;
  border: 1px dotted #80808033 !important;
}
#menu-appbar .Mui-selected {
  background-color: rgba(191, 191, 191, 0.1);
}
.react-trello-lane span {
  cursor: pointer !important;
}
@media (min-width: 1200px) {
  .gridVoteButton {
    -webkit-flex-basis: 11.333333% !important;
    -ms-flex-preferred-size: 11.333333% !important;
    flex-basis: 11.333333% !important;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 11.333333% !important;
  }

  .gridContent {
    -webkit-flex-basis: 88.666667% !important;
    -ms-flex-preferred-size: 88.666667% !important;
    flex-basis: 88.666667% !important;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 88.666667% !important;
  }
}
@media (max-width: 1024px) {
  .react-trello-board {
    justify-content: unset !important;
  }
}
.react-trello-board header {
  border: none !important;
}
.react-trello-board article {
  border: none !important;
}
/*# sourceMappingURL=App.css.map */
