@font-face {
    font-family: SFUI;
    src: url(../fonts/SFUIText-Semibold.eot);
    src: url(../fonts/SFUIText-Semibold.eot?#iefix) format("embedded-opentype"), url(../fonts/SFUIText-Semibold.woff2) format("woff2"), url(../fonts/SFUIText-Semibold.woff) format("woff"),
        url(../fonts/SFUIText-Semibold.ttf) format("truetype"), url(../fonts/SFUIText-Semibold.svg#SFUIText-Semibold) format("svg");
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: SFUI;
    src: url(../fonts/SFUIText-Medium.eot);
    src: url(../fonts/SFUIText-Medium.eot?#iefix) format("embedded-opentype"), url(../fonts/SFUIText-Medium.woff2) format("woff2"), url(../fonts/SFUIText-Medium.woff) format("woff"),
        url(../fonts/SFUIText-Medium.ttf) format("truetype"), url(../fonts/SFUIText-Medium.svg#SFUIText-Medium) format("svg");
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: SFUI;
    src: url(../fonts/SFUIText-Regular.eot);
    src: url(../fonts/SFUIText-Regular.eot?#iefix) format("embedded-opentype"), url(../fonts/SFUIText-Regular.woff2) format("woff2"), url(../fonts/SFUIText-Regular.woff) format("woff"),
        url(../fonts/SFUIText-Regular.ttf) format("truetype"), url(../fonts/SFUIText-Regular.svg#SFUIText-Regular) format("svg");
    font-weight: 400;
    font-style: normal;
}

*{
    color: #324057;
    font-family: "SFUI", system-ui, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
}
html{
    background-color: #F9FAFC;
}
.MuiTypography-root{
    font-family: "SFUI", system-ui, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
    line-height: 1 !important;
}
.MuiButton-startIcon{
	display: block !important;
    position: absolute !important;
    margin: 0% !important;
}
.MuiAlert-root *,.MuiButton-contained *,{
    color: #fff !important;
}
.count:before{
    content: ' ';
    display: block;
    margin-bottom: 20px;
}
.CookieConsent div:not(.gdpr_content){
    align-self: center;
}
.MuiBackdrop-root{
    background-color: transparent !important;
}

#mui-component-select-roadmap_id,
#mui-component-select-sorted_by{
    padding-bottom: 0px;
}
.MuiPaper-elevation1{
    box-shadow: rgb(0 0 0 / 0%) 0px 2px 1px -1px, rgb(0 0 0 / 0%) 0px 1px 1px 0px, rgb(0 0 0 / 5%) 0px 1px 1px 0px !important;
}
.MuiSelect-standard{
    font-size: 0.9rem !important;
    font-weight: bold !important;
    letter-spacing: 0px !important;
}
.MuiMenuItem-root{
     font-size: 14px !important;
}
.MuiButton-outlined{
    border: 1px solid rgb(215 215 215 / 50%) !important;
    border-radius: 12px !important;
}
.react-trello-lane{
    background-color: #fff !important;
    border-radius: 13px !important;
    padding: 20px !important;
    border : 1px dotted #80808033 !important;
}
#menu-appbar .Mui-selected{
    background-color: rgba(191, 191, 191, 10%);
}

.react-trello-lane span {cursor: pointer !important;}


@media (min-width: 1200px){
    .gridVoteButton {
        -webkit-flex-basis: 11.333333% !important;
        -ms-flex-preferred-size: 11.333333% !important;
        flex-basis: 11.333333% !important;
        -webkit-box-flex: 0;
        -webkit-flex-grow: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
        max-width: 11.333333% !important;
    }
    .gridContent {
        -webkit-flex-basis: 88.666667% !important;
        -ms-flex-preferred-size: 88.666667% !important;
        flex-basis: 88.666667% !important;
        -webkit-box-flex: 0;
        -webkit-flex-grow: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
        max-width: 88.666667% !important;
    }

}

@media (max-width: 1024px) {
    .react-trello-board {
        justify-content: unset !important
    }
}



.react-trello-board header {
    border: none !important;
}

.react-trello-board article {
    border: none !important;
}
